import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon, faBars, faTimes, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import './index.css';
import { contactRef, homeRef, servicesRef, faqsRef, productsRef, policyRef, directoryRef, careersRef, blogRef, aboutRef, eventsRef, tbn247Ref } from '../refs';

const Header = ({ toggleTheme, isDarkMode, scrollToSection, activeRef }) => {
  const [activeLink, setActiveLink] = useState(activeRef == policyRef ? 'policy' : 'home');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openGroup, setOpenGroup] = useState(null);

  useEffect(() => {
    if(activeRef == policyRef){
      setActiveLink('policy');
    }
  }, [activeRef]);

  const handleLinkClick = (link) => {
    setActiveLink(link);
    const refMap = {
      home: homeRef,
      services: servicesRef,
      contact: contactRef,
      faqs: faqsRef,
      // products: productsRef,
      policy: policyRef,
      directory: directoryRef,
      careers: careersRef,
      blog: blogRef,
      about: aboutRef,
      events: eventsRef,
      tbn247: tbn247Ref
    };
    scrollToSection(refMap[link.toLowerCase()]);
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleGroup = (group) => {
    setOpenGroup(openGroup === group ? null : group);
  };

  const navGroups = [
    {
      name: 'Home',
      items: ['Home', 'Services', 'Contact', 'FAQs']
    },
    {
      name: 'Info',
      items: ['About', 'Blog', 'Events']
    },
    {
      name: 'Company',
      items: ['Careers', 'Directory', 'Policy']
    },
    // {
    //   name: 'Products',
    //   items: ['TBN247']
    // },
    {
      name: 'Contact',
      items: ['Contact']
    }
  ];

  return (
    <header className="header">
      <div className="logo" onClick={() => scrollToSection(homeRef)} style={{ userSelect: 'none' }}>HSX</div>
      <div className="nav-and-toggle">
        <nav className={`nav ${isMenuOpen ? 'open' : ''}`}>
          {navGroups.map((group) => (
            <div key={group.name} className="nav-group">
              <button className="group-toggle" onClick={() => toggleGroup(group.name)}>
                {group.name} <FontAwesomeIcon icon={faChevronDown} className={openGroup === group.name ? 'rotate' : ''} />
              </button>
              <div className={`group-items ${openGroup === group.name ? 'open' : ''}`}>
                {group.items.map((link) => (
                  <a 
                    key={link.toLowerCase()}
                    href={`#${link.toLowerCase()}`} 
                    className={activeLink === link.toLowerCase() ? 'active' : ''}
                    onClick={(e) => {
                      e.preventDefault();
                      handleLinkClick(link.toLowerCase());
                    }}
                  >
                    {link}
                  </a>
                ))}
              </div>
            </div>
          ))}
        </nav>
        <button className="theme-toggle" onClick={toggleTheme} aria-label="Toggle theme">
          <FontAwesomeIcon icon={isDarkMode ? faSun : faMoon} />
        </button>
        <button className="menu-toggle" onClick={toggleMenu} aria-label="Toggle menu">
          <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
        </button>
      </div>
    </header>
  );
};

export default Header;