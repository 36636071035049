import React, { useState } from 'react';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faNewspaper, faDesktop, faCloud } from '@fortawesome/free-solid-svg-icons';


const TBN247 = () => {
    const productData = {
        id: 'tbn247',
        title: "TBN247 News Portal",
        description: "Premier online news portal based in South Asia, delivering real-time news and insights across various topics.",
        features: [
            { icon: faGlobe, text: "24/7 news coverage with a focus on South Asia" },
            { icon: faNewspaper, text: "Expanding into Europe with AI-driven insights" },
            { icon: faDesktop, text: "Responsive design for seamless browsing" },
            { icon: faCloud, text: "Real-time updates on regional affairs" },
        ],
        image: require('../../../icons/images/tbn247.png'),
        link: "https://tbn247.com/",
        playStore: "https://play.google.com/store/apps/details?id=com.news.ui",
        appStore: "https://apps.apple.com/us/app/tbn247-news/id6444491222",
        releaseDate: [
            { 
                version: "7.0.0", 
                releaseDate: "2024-12-08", 
                notes: ["Added feature to attach videos to news", "Minor bug fixes", "Performance improvements"],
                viewDetails: "This version introduces the ability to attach videos directly to news articles, enhancing the multimedia experience for users. Additionally, several minor bugs have been fixed, and overall performance has been improved for a smoother user experience.",
                shareDetails: "Share version 7.0.0"
            },
            { 
                version: "6.1.3", 
                releaseDate: "2024-11-08", 
                notes: ["Minor bug fixes", "Performance improvements", "Updated user interface"],
                viewDetails: "This update focuses on minor bug fixes and performance improvements, ensuring a smoother user experience. The user interface has also been updated for better usability.",
                shareDetails: "Share version 6.1.3"
            },
            { 
                version: "6.1.2", 
                releaseDate: "2024-10-08", 
                notes: ["Introduced a new section for editorial articles", "Enhanced app security", "Improved user interface for better accessibility"],
                viewDetails: "Version 6.1.2 introduces a new section dedicated to editorial articles, enhancing content diversity. Security enhancements and user interface improvements for better accessibility are also included.",
                shareDetails: "Share version 6.1.2"
            },
            { 
                version: "6.1.1", 
                releaseDate: "2024-09-08", 
                notes: ["Added a new feature for live event coverage", "Fixed issues with video streaming", "Improved user feedback system"],
                viewDetails: "This version adds a feature for live event coverage, allowing users to stay updated in real-time. Video streaming issues have been resolved, and the user feedback system has been improved.",
                shareDetails: "Share version 6.1.1"
            },
            { 
                version: "6.1.0", 
                releaseDate: "2024-08-08", 
                notes: ["Launched a new podcast section", "Enhanced audio quality for podcasts", "Improved app navigation"],
                viewDetails: "Version 6.1.0 launches a new podcast section, offering enhanced audio quality. App navigation has been improved for a more intuitive user experience.",
                shareDetails: "Share version 6.1.0"
            },
            { 
                version: "6.0.0", 
                releaseDate: "2024-07-08", 
                notes: ["Major redesign of the app", "Improved performance and stability", "New features added"],
                viewDetails: "This major update includes a complete redesign of the app, improving both performance and stability. Several new features have been added to enhance user engagement.",
                shareDetails: "Share version 6.0.0"
            },
            { 
                version: "5.0.0", 
                releaseDate: "2024-06-08", 
                notes: ["Introduced a new widget for weather updates", "Fixed bugs related to user login", "Improved article sharing options"],
                viewDetails: "Version 5.0.0 introduces a new widget for weather updates, fixes user login bugs, and improves article sharing options for a better user experience.",
                shareDetails: "Share version 5.0.0"
            },
            { 
                version: "4.0.0", 
                releaseDate: "2024-05-08", 
                notes: ["Added a new feature for bookmarking articles", "Enhanced offline reading capabilities", "Improved app stability"],
                viewDetails: "This update adds a bookmarking feature for articles, enhances offline reading capabilities, and improves overall app stability.",
                shareDetails: "Share version 4.0.0"
            },
            { 
                version: "3.0.0", 
                releaseDate: "2024-04-08", 
                notes: ["Introduced personalized news recommendations", "Fixed issues with push notifications", "Improved video playback quality"],
                viewDetails: "Version 3.0.0 introduces personalized news recommendations, resolves push notification issues, and improves video playback quality for a better viewing experience.",
                shareDetails: "Share version 3.0.0"
            },
            { 
                version: "2.0.0", 
                releaseDate: "2024-03-08", 
                notes: ["Launched a new section for live news updates", "Enhanced security features", "Optimized app performance"],
                viewDetails: "This version launches a new section for live news updates, enhances security features, and optimizes app performance for faster loading times.",
                shareDetails: "Share version 2.0.0"
            },
            { 
                version: "1.0.0", 
                releaseDate: "2024-02-08", 
                notes: ["Initial release of TBN247 News Portal", "24/7 news coverage with a focus on South Asia", "Expanding into Europe with AI-driven insights", "Responsive design for seamless browsing", "Real-time updates on regional affairs"],
                viewDetails: "The initial release of TBN247 News Portal offers 24/7 news coverage focused on South Asia, with plans to expand into Europe using AI-driven insights. It features a responsive design for seamless browsing and real-time updates on regional affairs.",
                shareDetails: "Share version 1.0.0"
            }
        ]
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null)

    const openModal = (content) => {
        setModalContent(content);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent(null)
    };


    return (
        <div className="tbn247-container">
            <h1>{productData.title}</h1>
            <img src={productData.image} alt={productData.title} className="tbn247-image" />
            <p>{productData.description}</p>
            <div className="features-list">
                {productData.features.map((feature, index) => (
                    <div key={index} className="feature-item">
                        <FontAwesomeIcon icon={feature.icon} />
                        <span>{feature.text}</span>
                    </div>
                ))}
            </div>
            <div className="tbn247-links">
                <a href={productData.link} className="tbn247-link" target="_blank" rel="noopener noreferrer">Visit TBN247</a>
                <a href={productData.playStore} className="tbn247-link" target="_blank" rel="noopener noreferrer">Play Store</a>
                {/* <a href={productData.appStore} className="tbn247-link" target="_blank" rel="noopener noreferrer">App Store</a> */}
            </div>
            <div className="release-notes">
                <h2>Release Notes</h2>
                {productData.releaseDate.map((release, index) => (
                    <div key={index} className="release-item">
                        <h3>Version {release.version} - {new Date(release.releaseDate).toLocaleDateString()}</h3>
                        <ul>
                            {release.notes.map((note, noteIndex) => (
                                <li key={noteIndex}>{note}</li>
                            ))}
                        </ul>
                        <div className="release-actions">
                            <button className="release-button" onClick={() => openModal(release)}>View Details</button>
                            <button className="release-button" onClick={() => navigator.clipboard.writeText(release.shareDetails)}>
                                Copy
                                <FontAwesomeIcon icon={['fas', 'copy']} style={{ marginLeft: '0.5rem' }} />
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            {isModalOpen && modalContent && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <div className="modal-body">
                            <h2>Release Note Details - Version {modalContent.version}</h2>
                            <p>Release Date: {new Date(modalContent.releaseDate).toLocaleDateString()}</p>
                            <h3>Notes:</h3>
                            <ul>
                                {modalContent.notes.map((note, index) => (
                                    <li key={index}>{note}</li>
                                ))}
                            </ul>
                            <p>{modalContent.viewDetails}</p>
                        </div>
                    </div>
                </div>
            )}
            
        </div>
    );
};

export default TBN247;
