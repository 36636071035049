import { createRef } from 'react';

export const homeRef = createRef();
export const servicesRef = createRef();
export const contactRef = createRef();
export const faqsRef = createRef();
export const productsRef = createRef();

export const policyRef = createRef();
export const directoryRef = createRef();
export const aboutRef = createRef();
export const careersRef = createRef();
export const blogRef = createRef();
export const newsRef = createRef();
export const eventsRef = createRef();
export const publicationsRef = createRef();
export const tbn247Ref = createRef();
